import { SharedModule } from './../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SharkTableModule } from 'shark-ng-table';

import { DatabaseRoutingModule } from './database-routing.module';
import { DatabaseFormComponent } from './database-form/database-form.component';
import { DbMonitorComponent } from './db-monitor/db-monitor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    DbMonitorComponent,
    DatabaseFormComponent
    ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharkTableModule,
    DatabaseRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule
  ]
})
export class DatabaseModule { }
