import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbMonitorService } from '../Databases/_services/db-monitor.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private dbMonServ: DbMonitorService) { }
  dbNames: any;
  ngOnInit(): void {
    this.getDatabases();
  }

  getAZSQLDB(path1) {
    this.router.navigateByUrl(path1);
  }

  getDatabases() {
    this.dbMonServ.getDatabases().subscribe(response =>
    {
      this.dbNames = response;
      console.log(this.dbNames);
    }, error => {
      console.log(error);

    });
  }
}
