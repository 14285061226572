import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DbMonitorComponent } from './Databases/db-monitor/db-monitor.component';
import { DatabaseFormComponent } from './databases/database-form/database-form.component';
import { SeimDashnoardComponent } from './seim/seim-dashnoard/seim-dashnoard.component';

const routes: Routes = [
  { path: '', component: SeimDashnoardComponent },
  { path: 'AZSQL', component: DbMonitorComponent },
  { path: 'admin', component: DatabaseFormComponent },
  { path:'seimdb',component:SeimDashnoardComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
