export var platform = [
    {
      "name": "MacOS",
      "value": 23
    },
    {
      "name": "Android",
      "value": 6
    },
    {
      "name": "Windows 10",
      "value": 55
    },
    {
      "name": "IOS 15",
      "value": 15
    }
];
  
export var browser = [
    {
      "name": "Chrome",
      "value": 534
    },
    {
      "name": "Safari",
      "value": 123
    },
    {
      "name": "Opera",
      "value": 46
    },
    {
      "name": "Firefox",
      "value": 9
    },
    {
      "name": "Edge",
      "value": 9
    },
    {
      "name": "Mobile",
      "value": 7
    }
];
  
export var authApp = [
    {
      "name": "0cc3c5db-c8aa-43f8-b0d8-d9e32c60290f",
      "value": 411
    },
    {
      "name": "d8971354-fa9b-4408-a129-5ec8b207d886",
      "value": 53
    },
    {
      "name": "b0606f12-5558-4f4d-98f1-c3cc35e69a62",
      "value": 46
    },
    {
      "name": "40f1a44c-c269-4556-b368-f7cf8fe3017c",
      "value": 24
    }
];
  
export var authbyProvider = [
  {
    "name": "local",
    "value": 257
  },
  {
    "name": "AzureAD",
    "value": 19
  },
  {
    "name": "Google",
    "value": 20
  },
  {
    "name": "AWS",
    "value": 2
  },
  {
    "name": "Facebook",
    "value": 1
  }
];

export var authPerPolicy = [
  {
    "name": "B2C_1A_Signup_Signin",
    "value": 111
  },
  {
    "name": "B2C_1A_susi_AADMultiTenant",
    "value": 107
  },
  {
    "name": "B2C_1A_Signup_Signin_loyality_number",
    "value": 61
  },
  {
    "name": "B2C_1A_Signup_Signin_AppInsights",
    "value": 56
  },
  {
    "name": "B2C_1A_susi_Conditional_Access_V1",
    "value": 39
  },
  {
    "name": "B2C_1A_Signup_Signin_saml_v2",
    "value": 23
  },
  {
    "name": "B2C_1A_CA_test",
    "value": 16
  },
  {
    "name": "B2C_1A_signon_client",
    "value": 11
  },
  {
    "name": "B2C_1_signin",
    "value": 6
  },
  {
    "name": "B2C_1A_SUSI",
    "value": 3
  }
];
  
export var authFailReason = [
  {
    "name": "Invalid Username or Password or involid on-prem user",
    "value": 26
  },
  {
    "name": "User need to complete multi-factor-authentication",
    "value": 10
  },
  {
    "name": "This error occured due to 'Keep me signed in' intrruption",
    "value": 8
  },
  {
    "name": "Invalid password, entered expired password",
    "value": 3
  },
  {
    "name": "other",
    "value": 3
  },
  {
    "name": "Session is invalid due to experation or recent password change",
    "value": 2
  },
  {
    "name": "Application X does not have access to Application Y",
    "value": 2
  },
  {
    "name": "Applcaion was not found in the directory",
    "value": 1
  },
  {
    "name": "user needs to enrolled in second facttor authentication",
    "value": 1
  }
];

  export var multi = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        }
      ]
    },
  
    {
      "name": "USA",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        }
      ]
    },
  
    {
      "name": "France",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    }
  ];


  export var FailedSIgnins = [
    {
      "name": "FailedSIgnins",
      "series": [
        {
          "name": "Nov 26",
          "value": "15"
        },
        {
          "name": "Nov 25",
          "value": "20"
        },
        {
          "name": "Nov 24",
          "value": "25"
        },
        {
          "name": "Nov 23",
          "value": "30"
        },
        {
          "name": "Nov 22",
          "value": "12"
        },
        {
          "name": "Nov 21",
          "value": "3"
        },
        {
          "name": "Nov 20",
          "value": "11"
        },
        {
          "name": "Nov 19",
          "value": "5"
        },
        {
          "name": "Nov 18",
          "value": "15"
        },
        {
          "name": "Nov 17",
          "value": "23"
        },
        {
          "name": "Nov 16",
          "value": "12"
        },
        {
          "name": "Nov 15",
          "value": "3"
        },
        {
          "name": "Nov 14",
          "value": "15"
        },
        {
          "name": "Nov 13",
          "value": "28"
        }
        
      ],
    }
  
  ];

  export var SuccessfulSIgnins = [
    {
      "name": "FailedSIgnins",
      "series": [
        {
          "name": "Nov 26",
          "value": "1534"
        },
        {
          "name": "Nov 25",
          "value": "2045"
        },
        {
          "name": "Nov 24",
          "value": "2235"
        },
        {
          "name": "Nov 23",
          "value": "3034"
        },
        {
          "name": "Nov 22",
          "value": "1232"
        },
        {
          "name": "Nov 21",
          "value": "3787"
        },
        {
          "name": "Nov 20",
          "value": "117"
        },
        {
          "name": "Nov 19",
          "value": "5787"
        },
        {
          "name": "Nov 18",
          "value": "1578"
        },
        {
          "name": "Nov 17",
          "value": "2387"
        },
        {
          "name": "Nov 16",
          "value": "1257"
        },
        {
          "name": "Nov 15",
          "value": "3567"
        },
        {
          "name": "Nov 14",
          "value": "5615"
        },
        {
          "name": "Nov 13",
          "value": "2823"
        }
        
      ],
    }
  
  ];
  