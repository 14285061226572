<div class="row">
    <div class="col-6" *ngFor="let db of dbNames">
        <div class="card m-3 text-center">
            <div class="card-header text-nowrap text-center">
                <h2>{{db.dbName}}</h2>
            </div>
            <div class="card-body">
                <p class="card-title text-center">{{db.description}}
                     </p>
               <button class="btn btn-primary" (click)="getAZSQLDB(db.dbName)">Go</button>
            </div>
        </div>
    </div>
   
</div>