import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DbMonitorComponent } from './db-monitor/db-monitor.component';
import { DatabaseFormComponent } from './database-form/database-form.component';

const routes: Routes = [
  { path: 'AZSQL', component: DbMonitorComponent },
  {path: 'admin', component: DatabaseFormComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class DatabaseRoutingModule { }
