<div class="m-3">
  <h1>Security Dashboard</h1>
</div>
<div class="row m-3"> 
    <h2> User Insights</h2>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="grid" >
   
<mat-card fxFlex.lg="45%" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="45%" fxFlex.gt-xl="45%" class="bargraph border m-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Users by OS</mat-card-title>
      <mat-card-subtitle>Number of users by operating system</mat-card-subtitle>  <hr/>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
        <ngx-charts-bar-vertical  [view]="view" [scheme]="colorScheme"  [results]="platform" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
            [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel" [legendPosition] = "alegendPosition" [showDataLabel] = "true"  (window:resize)="onResize($event)">
        </ngx-charts-bar-vertical>
      </div>
    </mat-card-content> 
  </mat-card>

  <mat-card fxFlex.lg="45%" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="45%" fxFlex.gt-xl="45%"  class="bargraph border m-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Users by Browser</mat-card-title>
      <mat-card-subtitle>Number of users accessing application by browser type</mat-card-subtitle>  <hr/>
    </mat-card-header>


    <mat-card-content>
      <div class="text-center">
        <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme" [results]="browser" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
            [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="'Browser'"
            [yAxisLabel]="yAxisLabel" [legendPosition] = "alegendPosition" [showDataLabel] = "true" (window:resize)="onResize($event)">
        </ngx-charts-bar-vertical>
      </div>
    </mat-card-content> 
  </mat-card>
</div>

<div class="row m-3"> 
    <h2> User Authentication</h2>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="grid">
    <mat-card fxFlex.lg="45%" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="45%" fxFlex.gt-xl="45%"  class="bargraph border m-3">
        <mat-card-header>
          <mat-icon class="success">check_circle</mat-icon>
          <mat-card-title>Authentication Per Application</mat-card-title>
          <mat-card-subtitle> </mat-card-subtitle>  <hr/>
        </mat-card-header>
    
    
        <mat-card-content>
          <div class="text-center">
            <ngx-charts-bar-horizontal [view]="view1" [scheme]="colorScheme" [results]="authApp" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="'Total Auth'"
                [yAxisLabel]="'Clinet id'" [showDataLabel] = "true" (window:resize)="onResize($event)">
            </ngx-charts-bar-horizontal>
          </div>
        </mat-card-content> 
      </mat-card>

      <mat-card fxFlex.lg="45%" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="45%" fxFlex.gt-xl="45%"  class="bargraph border m-3">
        <mat-card-header>
          <mat-icon class="success">check_circle</mat-icon>
          <mat-card-title>Authentication Per Identity Provider</mat-card-title>
          <mat-card-subtitle> </mat-card-subtitle>  <hr/>
        </mat-card-header>
    
    
        <mat-card-content>
          <div class="text-center">
            <ngx-charts-bar-horizontal [view]="view1" [scheme]="colorScheme" [results]="authbyProvider" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="'Identity Provider'"
                [yAxisLabel]="'Provider'" [showDataLabel] = "true" (window:resize)="onResize($event)">
            </ngx-charts-bar-horizontal>
          </div>
        </mat-card-content> 
      </mat-card>

</div>

<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="grid">
  <mat-card fxFlex.lg="45%" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="45%" fxFlex.gt-xl="45%"  class="bargraph border m-3">
      <mat-card-header>
        <mat-icon class="success">check_circle</mat-icon>
        <mat-card-title>Authentication Per Policy</mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>  <hr/>
      </mat-card-header>
  
  
      <mat-card-content>
        <div class="text-center">
          <ngx-charts-bar-horizontal [view]="view1"  [scheme]="colorScheme" [results]="authPerPolicy" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
              [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="'Total Auth'"
              [yAxisLabel]="'Policy'" [showDataLabel] = "true" (window:resize)="onResize($event)">
          </ngx-charts-bar-horizontal>
        </div>
      </mat-card-content> 
    </mat-card>

    <mat-card fxFlex.lg="45%" fxFlex.gt-xs="100%" fxFlex.gt-sm="100%" fxFlex.gt-md="45%" fxFlex.gt-xl="45%"  class="bargraph border m-3">
      <mat-card-header>
        <mat-icon class="success">check_circle</mat-icon>
        <mat-card-title>Authentication failure reasons</mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>  <hr/>
      </mat-card-header>
  
  
      <mat-card-content>
        <div class="text-center">
          <ngx-charts-bar-horizontal [view]="view1" [scheme]="colorScheme" [results]="authFailReason" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
              [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="'Count'"
              [yAxisLabel]="'Failure Reasons'" [showDataLabel] = "true" (window:resize)="onResize($event)">
          </ngx-charts-bar-horizontal>
        </div>
      </mat-card-content> 
    </mat-card>

</div>
<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="grid">

  <mat-card class="linegraph border m-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Failed Signin (Timechart)</mat-card-title>
      <mat-card-subtitle> </mat-card-subtitle>  <hr/>
    </mat-card-header>


    <mat-card-content>
      <div class="text-center">
        <ngx-charts-line-chart
  [view]="view2"
  [scheme]="colorScheme"
  [results]="FailedSIgnins"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="false"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="'Date'"
  [yAxisLabel]="'# faliures'">
</ngx-charts-line-chart>
   
      </div>
    </mat-card-content> 
  </mat-card>
  

</div>

<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="grid">

  <mat-card class="linegraph border m-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Successful Signin (Timechart)</mat-card-title>
      <mat-card-subtitle> </mat-card-subtitle>  <hr/>
    </mat-card-header>


    <mat-card-content>
      <div class="text-center">
        <ngx-charts-line-chart
  [view]="view2"
  [scheme]="colorScheme"
  [results]="SuccessfulSIgnins"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="false"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="'Date'"
  [yAxisLabel]="'# Success'">
</ngx-charts-line-chart>
   
      </div>
    </mat-card-content> 
  </mat-card>
  

</div>