import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeimDashnoardComponent } from './seim-dashnoard/seim-dashnoard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [SeimDashnoardComponent],
  imports: [
    CommonModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    SharedModule
  ]
})
export class SEIMModule { }
