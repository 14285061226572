<mat-toolbar class="topbar">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <img src="../assets/OperateEdge_BlackBackground.png" alt="OperateEdge" width="200px" height="60">
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
      <mat-nav-list>
        <mat-list-item>   
          <a matLine routerLink="/AZSQL"><span><mat-icon class="whiteIcon">data_usage</mat-icon>&nbsp; Database</span></a>
        </mat-list-item>
        <mat-list-item>   
            <a matLine routerLink="/seimdb"><span><mat-icon class="whiteIcon">security</mat-icon>&nbsp; Security</span></a>
          </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="ylb-app-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

