import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'nu-card-kpi',
  templateUrl: './card-kpi.component.html',
  styleUrls: ['./card-kpi.component.css']
})
export class CardKPIComponent implements OnInit, OnChanges {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() value: string = '';
  @Input() unit: string = '';
  @Input() lastUpdated: string = '';
  isLastupdate: boolean = false;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(): void {
    if (this.lastUpdated != '') { this.isLastupdate = true; } 
    else  { this.isLastupdate = false; }
  }

}
