import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardKPIComponent } from './card-kpi/card-kpi.component';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [CardKPIComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    CardKPIComponent
  ]
})
export class SharedModule { }
