

<div class="row">
  <div class="col"><h1>{{title}}</h1></div>
  <div class="col"><div class="d-flex flex-row-reverse">
    <mat-form-field appearance="fill">
      <mat-label>Database Name</mat-label>
      <mat-select (selectionChange)="getdatabaseDashboard($event.value)">
        <mat-option *ngFor="let db of dbNames" [value]="db.id" >
          {{db.dbName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div></div>
</div>

<div class="flex-container">
  <div class="flex-container" *ngFor='let database of cpu'>
    <div class="flex-item" *ngFor='let database of memory'>
      <nu-card-kpi title= "Memory Used" subtitle="Database Memory Usage" value = {{database.RAM}} unit = "%" lastUpdated = {{database.CDate}}></nu-card-kpi>
    </div>
  <div class="flex-item" >
    <nu-card-kpi title= "DB Process CPU" subtitle="Database CPU Usage" value = {{database.sql_server_process_cpu}} unit = "%" lastUpdated = {{database.event_time}}></nu-card-kpi> 
  </div>
  <div class="flex-item">
    <nu-card-kpi title= "DB Idle CPU" subtitle="Database Idle CPU" value = {{database.system_idle_process_cpu}} unit = "%" lastUpdated = {{database.event_time}}></nu-card-kpi>
  </div>
  <div class="flex-item">
    <nu-card-kpi title= "Other Process CPU" subtitle="Other Processes" value = {{database.other_process_cpu}} unit = "%" lastUpdated = {{database.event_time}}></nu-card-kpi>
  </div>
</div>
</div>



<hr>
<div class="row">
  <mat-card class="col border ml-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>DB Sessions</mat-card-title>
      <mat-card-subtitle>Possible session needs for the database</mat-card-subtitle>  <hr/>
    </mat-card-header>


    <mat-card-content>
      <div class="text-center">
        <ng-container *ngIf="isSession">
          <shark-table [data]="Sessions" [columns]="SessionColumns" [filterable]="true" [hideCaption]="true">
          </shark-table>
        </ng-container>
        <div *ngIf="!isSession"> No data found</div>
      </div>
    </mat-card-content> 
  </mat-card>


  <mat-card class="col border ml-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Concurrent requests</mat-card-title>
      <mat-card-subtitle>Count of concurrent requests in that database</mat-card-subtitle>  <hr/>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
        <ng-container *ngIf="isConCur">
          <shark-table [data]="ConCur" [columns]="ConCurColumns" [filterable]="true" [hideCaption]="true">
          </shark-table>
        </ng-container>
        <div *ngIf="!isConCur"> No data found</div>
      </div>
    </mat-card-content> 
  </mat-card>

  <mat-card class="col border ml-3 mr-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Connections</mat-card-title>
      <mat-card-subtitle>Information on the current connection</mat-card-subtitle>  <hr/>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
        <ng-container *ngIf="isConn">
          <shark-table [data]="Conn" [columns]="ConnColumns" [filterable]="true" [hideCaption]="true">
          </shark-table>
        </ng-container>
        <div *ngIf="!isConn"> No data found</div>
      </div>
    </mat-card-content> 
  </mat-card>
</div>

<hr>
<div class="row">
<mat-card class="colcard border m-3">
  <mat-card-header>
    <mat-icon class="success">check_circle</mat-icon>
    <mat-card-title>Database Resource Stats</mat-card-title>
    <mat-card-subtitle>The average and maximum resource use for the current database
      over the past hour</mat-card-subtitle>  <hr/>
  </mat-card-header>
  <mat-card-content>
    <div class="text-center">
      <ng-container *ngIf="isResourceStats">
        <shark-table [data]="ResourceStats" [columns]="ResourceStatsColumns" [filterable]="true" [hideCaption]="true">
        </shark-table>
      </ng-container>
      <div *ngIf="!isResourceStats"> No data found</div>
    </div>
  </mat-card-content> 
</mat-card>
</div>


<div class="row">
  <mat-card class="colcard border m-3">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>Top "N" Queries</mat-card-title>
      <mat-card-subtitle>The top "N" queries ranked by average CPU time</mat-card-subtitle>  <hr/>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
        <ng-container *ngIf="istopN">
          <shark-table [data]="topN" [columns]="TopNColumns" [filterable]="true" [hideCaption]="true">
          </shark-table>
        </ng-container>
        <div *ngIf="!istopN"> No data found</div>
      </div>
    </mat-card-content> 
  </mat-card>
  </div>

  <div class="row">
    <mat-card class="colcard border m-3">
      <mat-card-header>
        <mat-icon class="success">check_circle</mat-icon>
        <mat-card-title>Blocks</mat-card-title>
        <mat-card-subtitle>The top "N" queries ranked by average CPU time</mat-card-subtitle>  <hr/>
      </mat-card-header>
      <mat-card-content>
        <div class="text-center">
          <ng-container *ngIf="isBlocks">
            <shark-table [data]="blockers" [columns]="BlocksColumns" [filterable]="true" [hideCaption]="true">
            </shark-table>
          </ng-container>
          <div *ngIf="!isBlocks"> No data found</div>
        </div>
      </mat-card-content> 
    </mat-card>
    </div>



    <div class="row">
      <mat-card class="colcard border m-3">
        <mat-card-header>
          <mat-icon class="success">check_circle</mat-icon>
          <mat-card-title>Query Plans</mat-card-title>
          <mat-card-subtitle>Which query uses the most cumulative CPU</mat-card-subtitle>  <hr/>
        </mat-card-header>
        <mat-card-content>
          <div class="text-center">
            <ng-container *ngIf="isQueryPlans">
              <shark-table [data]="QueryPlans" [columns]="QueryPlansColumns" [filterable]="true" [hideCaption]="true">
              </shark-table>
            </ng-container>
            <div *ngIf="!isQueryPlans"> No data found</div>
          </div>
        </mat-card-content> 
      </mat-card>
      </div>
