import { Component, ElementRef, OnInit } from '@angular/core';
import { LegendPosition, Color, ScaleType } from '@swimlane/ngx-charts';
import { iColor, colorSets } from 'src/app/shared/Color/color';
import { platform, browser, authApp, authbyProvider,authPerPolicy, authFailReason, FailedSIgnins, SuccessfulSIgnins } from './data';

@Component({
  selector: 'app-seim-dashnoard',
  templateUrl: './seim-dashnoard.component.html',
  styleUrls: ['./seim-dashnoard.component.css']
})
export class SeimDashnoardComponent implements OnInit {
  
  
  ngOnInit(): void {
    if (window.innerWidth > 1300) {
      this.view = [window.innerWidth / 2.70, 150];
      this.view1 = [window.innerWidth / 2.70, 230];
      this.view2 = [window.innerWidth / 1.35, 230];
    }
    else {
      this.view = [window.innerWidth / 1.35, 150];
      this.view1 = [window.innerWidth / 1.35, 230];
      this.view2 = [window.innerWidth / 1.35, 230];
    }
  }
  platform: any[];
  browser: any[];
  authApp: any[];
  authbyProvider: any[];
  authPerPolicy: any[];
  authFailReason: any[];
  FailedSIgnins: any[];
  SuccessfulSIgnins: any[];
  multi: any[];
  
  view: [number, number] ;
  view1: [number, number] ;
  view2: [number, number] ;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Platform';
  showYAxisLabel = true;
  yAxisLabel = '# of Users';
  alegendPosition = LegendPosition.Below;
  colorSets: any;
  colorScheme: any;
  schemeType = ScaleType.Ordinal;
  selectedColorScheme: string;
  
  onResize(event) {
    if (window.innerWidth > 1300) {
    this.view = [event.target.innerWidth / 2.70, 150];
    this.view1 = [event.target.innerWidth / 2.70, 230];
      this.view2 = [event.target.innerWidth / 1.35, 230];
    }
    else {
      this.view = [event.target.innerWidth / 1.35, 150];
      this.view1 = [event.target.innerWidth / 1.35, 230];
      this.view2 = [event.target.innerWidth / 1.35, 230];
    }
    
  }

  constructor() {
    Object.assign(this, { platform })
    Object.assign(this, { browser })
    Object.assign(this, { authApp })
    Object.assign(this, { authbyProvider })
    Object.assign(this, { authPerPolicy })
    Object.assign(this, { authFailReason })
    Object.assign(this, { FailedSIgnins })
    Object.assign(this, { SuccessfulSIgnins })
    Object.assign(this, { colorSets })

    this.setColorScheme('Deloitte');
  }



  setColorScheme(name) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find(s => s.name === name);
  }
}
