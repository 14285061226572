import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DbMonitorService {
  // baseUrl = "https://localhost:5001/api/"
  baseUrl = environment.apiurl;
  constructor(private http: HttpClient) { }

  getKPI(id: any, kpi: any) {
    return this.http.get(this.baseUrl + 'SQLServerQueriesExecution/' + id + '/' + kpi).pipe(
      tap(data =>  JSON.stringify(data)),
     // catchError(this.handleError)
    );
  }

  getDatabases()  {
    return this.http.get(this.baseUrl + 'Databases');
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An Error Occured: ${err.error.message}`;
    } else
    {
      errorMessage = `Server Error code: ${err.status} , error Message is ${err.message} `;
    }
    console.log(errorMessage);
    return errorMessage;
  }


}
