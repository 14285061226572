import { environment } from './../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { SharkColumn, SharkDynamicContents } from 'shark-ng-table';
import {} from 'src/environments/environment';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('drawer') drawer: any;
 public selectedItem : string = '';
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));


  constructor(private breakpointObserver: BreakpointObserver) {}

closeSideNav() {
  if (this.drawer._mode=='over') {
    this.drawer.close();
  }
}
  

  ngOnInit() {



  }


}
