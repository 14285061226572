  <mat-card class="nu-card">
    <mat-card-header>
      <mat-icon class="success">check_circle</mat-icon>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>  <hr/>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
        <em class="card-title ">{{value | number : '1.2-2'}}{{unit}}</em>    
      </div>
    </mat-card-content>
    <mat-card-actions>
      <small class="card-text text-right" *ngIf = "isLastupdate">as of  {{ lastUpdated | date : 'long'}}</small>
    </mat-card-actions>
  </mat-card>
