
import { DbMonitorService } from '../_services/db-monitor.service';
import { Component, OnInit } from '@angular/core';
import { Observable, pipe, ReplaySubject, Subscription, timer } from 'rxjs';
import { SharkColumn } from 'shark-ng-table';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'dbmonitor-selector',
  templateUrl: './db-monitor.component.html',
  styleUrls: ['./db-monitor.component.css']
})
export class DbMonitorComponent implements OnInit {

  constructor(private dbMonServ: DbMonitorService) { }
  errorMessage = '';

  // getter and Setter for Database ID, which will be execute when you setup the ID
  private _id = 0;
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
    this.sub = this.everyTimerSeconds$.subscribe(() => {
      this.getMemory();
      this.getCPU();
      this.getTopN();
      this.getBlocks();
      this.getSessions();
      this.getQueryPlans();
      this.getConCur();
      this.getResourceStats();
      this.getConn();
    });
  }
  // BY this way you are declaring that you will use this variable later
  memoryTitle: string = "Memory";
  sub!: Subscription;
  everyTimerSeconds$: Observable<number> = timer(0, 30000);
  title = 'Database Dashboard';
  memory: any;

  memoryClass: string; 
  isMemory: boolean;
  cpu: any;
  isCPU = false;
  topN: any;
  istopN: boolean;
  blockers: any;
  isBlocks: boolean;
  dbNames: any;
  dbId: any;
  TopNColumns: SharkColumn[] = [];
  BlocksColumns: SharkColumn[] = [];
  SessionColumns: SharkColumn[] = [];
  Sessions: any;
  isSession: boolean;
  QueryPlansColumns: SharkColumn[] = [];
  QueryPlans: any;
  isQueryPlans: boolean;

  ConCurColumns: SharkColumn[] = [];
  ConCur: any;
  isConCur: boolean;

  ResourceStatsColumns: SharkColumn[] = [];
  ResourceStats: any;
  isResourceStats: boolean;

  ConnColumns: SharkColumn[] = [];
  Conn: any;
  isConn: boolean;

  // Implementation of OnINIT. Get the list of the databases
  ngOnInit(): void {
    this.id = 1;
    this.getDatabases();
  }

  // Get the memory counters
  getMemory() {
    this.sub = this.dbMonServ.getKPI(this.id, 'MEMORY').subscribe({
      next: response => {
        this.memory = response[0];
        
       console.log(response[1]);
        if (this.memory == null) { this.isMemory = false; } else { this.isMemory = true; }
      },
      error: err => {
        this.errorMessage = err;
        this.memory = null;
        this.isMemory = false;
        console.log(err);
      }
    });
  }

  // Get the CPU Counters
  getCPU() {

    this.sub = this.dbMonServ.getKPI(this.id, 'CPU').subscribe({
      next: response => {
        this.cpu = response[0];
        //console.log(response);
        if (this.cpu == null) { this.isCPU = false; } else { this.isCPU = true; }
      },
      error: (err => {
        this.errorMessage = err; this.cpu = null; this.isCPU = false; console.log(err);
      })
    });

  }

  // Get Top N queries
  getTopN() {
    this.sub = this.dbMonServ.getKPI(this.id, 'TOPN').subscribe({
      next: response => {
        this.topN = response[0];
        this.TopNColumns = [];
        if (this.topN == null) { this.istopN = false; } else {
          for (const key in this.topN[0]) {
            this.TopNColumns.push({ header: key, property: key });
          }
          this.istopN = true;
        }
      },
      error: (err => {
        this.errorMessage = err; this.topN = null; this.istopN = false; console.log(err);
      })
    });
  }

  // Get the Blocks
  getBlocks() {
    this.dbMonServ.getKPI(this.id, 'BLOCKS').subscribe(response => {
      this.blockers = response[0];
      this.BlocksColumns = [];
      if (this.blockers == null) { this.isBlocks = false; } else {
        for (const key in this.blockers[0]) {
          this.BlocksColumns.push({ header: key, property: key });
        }
        this.isBlocks = true;
      }
    }, error => {
      this.blockers = null;
      this.isBlocks = false;
      console.log(error.error);

    });
  }

  getSessions() {
    this.dbMonServ.getKPI(this.id, 'SESSIONS').subscribe(response => {
      this.Sessions = response[0];
      this.SessionColumns = [];
      if (this.Sessions == null) { this.isSession = false; } else {
        for (const key in this.Sessions[0]) {
          this.SessionColumns.push({ header: key, property: key });
        }
        this.isSession = true;
      }
    }, error => {
      this.Sessions = null;
      this.isSession = false;
      console.log(error.error);

    });
  }

  getQueryPlans() {
    this.dbMonServ.getKPI(this.id, 'QUERYPLANS').subscribe(response => {
      this.QueryPlans = response[0];
      this.QueryPlansColumns = [];
      if (this.QueryPlans == null) { this.isQueryPlans = false; } else {
        for (const key in this.QueryPlans[0]) {
          this.QueryPlansColumns.push({ header: key, property: key });
        }
        this.isQueryPlans = true;
      }
    }, error => {
      this.QueryPlans = null;
      this.isQueryPlans = false;
      console.log(error.error);

    });
  }


  getConCur() {
    this.dbMonServ.getKPI(this.id, 'CONCURRENT').subscribe(response => {
      this.ConCur = response[0];
      this.ConCurColumns = [];
      if (this.ConCur == null) { this.isConCur = false; } else {
        for (const key in this.ConCur[0]) {
          this.ConCurColumns.push({ header: key, property: key });
        }
        this.isConCur = true;
      }
    }, error => {
      this.ConCur = null;
      this.isConCur = false;
      console.log(error.error);

    });
  }

  getResourceStats() {
    this.dbMonServ.getKPI(this.id, 'RESOURCE_STATS').subscribe(response => {
      this.ResourceStats = response[0];
      this.ResourceStatsColumns = [];
      if (this.ResourceStats == null) { this.isResourceStats = false; } else {
        for (const key in this.ResourceStats[0]) {
          this.ResourceStatsColumns.push({ header: key, property: key });
        }
        this.isResourceStats = true;
      }
    }, error => {
      this.ResourceStats = null;
      this.isResourceStats = false;
      console.log(error.error);

    });
  }

  getConn() {
    this.dbMonServ.getKPI(this.id, 'CONNECTIONS').subscribe(response => {
      this.Conn = response[0];
      this.ConnColumns = [];
      if (this.Conn == null) { this.isConn = false; } else {
        for (const key in this.Conn[0]) {
          this.ConnColumns.push({ header: key, property: key });
        }
        this.isConn = true;
      }
    }, error => {
      this.Conn = null;
      this.isConn = false;
      console.log(error.error);

    });
  }

  getDatabases() {
    this.dbMonServ.getDatabases().subscribe(response => {
      this.dbNames = response;
      //console.log(this.dbNames);
    }, error => {
      console.log(error);

    });
  }

  getdatabaseDashboard(e) {
    this.id = e;
    console.log('test');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }



}
/*
ColumnName: null
Frequency: "30"
ID: "1"
KPICompareSign: "gt"
KPIId: "MEMORY"
KPItype: "number"
LowerAlert: "10"
LowerCritical: "0"
LowerWarning: "20"
OkRange: "60"
QueryName: "SELECT  AVG(avg_memory_usage_percent) AS 'RAM',getdate() AS 'CDate' FROM sys.dm_db_resource_stats;  "
UpperAlert: "90"
UpperCritical: "80"
UpperWarning: "70"
dbTypeName: "DBAPI.Models.DbTypeName"

*/